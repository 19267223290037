import React from 'react'

const Footer = props => (
  <footer id="footer">
    <ul className="icons">
      <li>
        <a href="/#Contact" className="icon solid alt fa-envelope">
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
    <ul className="copyright">
      <li>&copy; Eastlake Digital Media®, LLC. All Rights Reserved.</li>
      <li>
      Eastlake Digital Media and its logos are trademarks or registered trademarks of Eastlake Digital Media, LLC.
      </li>
    </ul>
  </footer>
)

export default Footer
